<template>
  <div class="login">
    <!--
    <div class="">
      <h3>注册</h3>
    </div>-->
    <el-tabs type="card" v-model="registeredType" @tab-click="handleTabClick">
      <el-tab-pane
        label="手机注册"
        name="phone"
        v-if="registeredType == 'phone'"
      >
        <el-form
          class="login-form level"
          ref="loginForm"
          :model="form"
          :rules="rules"
        >
          <el-form-item prop="user">
            <p class="label">
              <i class="iconfont iconshouji"></i>
              <label>手机号码</label>
            </p>
            <el-input
              v-model="form.user"
              placeholder="请输入手机号码"
            ></el-input>
            <span class="overseasText" @click="overseasRegisterMail"
              >海外用户？点这里 ></span
            >
          </el-form-item>
          <el-form-item prop="captcha">
            <p class="label">
              <i class="iconfont iconyanzhengma"></i>
              <label>验证码</label>
            </p>
            <el-input v-model="form.captcha" placeholder="验证码"></el-input>
            <img
              style="height: 32px;width: 96px;border-radius: 4px;"
              alt="点击刷新"
              title="点击刷新"
              class="pointer"
              :src="codeUrl"
              @click="refreshCode"
            />
          </el-form-item>

          <el-form-item prop="verCode">
            <p class="label">
              <i class="iconfont iconduanxinma"></i>
              <label>短信码</label>
            </p>
            <el-input
              max="6"
              v-model="form.verCode"
              placeholder="请输入短信码"
            ></el-input>
            <div class="code" @click="sendCode" v-if="!isSendCode">
              发送手机验证码
            </div>
            <div class="code" v-else>{{ count }}S后重发</div>
          </el-form-item>
          <el-form-item prop="password">
            <p class="label">
              <i class="iconfont iconmima1"></i>
              <label>密码</label>
            </p>
            <el-input
              type="password"
              v-model="form.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="passwordConfirm">
            <p class="label">
              <i class="iconfont iconmima1"></i>
              <label>确认密码</label>
            </p>
            <el-input
              type="password"
              v-model="form.passwordConfirm"
              placeholder="请再次输入密码"
              @keyup.enter.native="submitRegister"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sign_up_hidden_faiUuid">
            <p class="label">
              <i class="iconfont iconwodeyaoqing"></i>
              <label>邀请码</label>
            </p>
            <el-input
              v-model="form.sign_up_hidden_faiUuid"
              placeholder="非必填，如无请留空即可"
            ></el-input>
          </el-form-item>
          <el-form :inline="true">
            <el-form-item class="btn">
              <a class="btn-black" @click="submitRegister">注册</a>
            </el-form-item>
            <el-form-item class="btn">
              <a class="btn-border" @click="goBack">
                <i class="iconfont iconyonghu"></i>
                <span>返回登录</span>
              </a>
            </el-form-item>
          </el-form>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Email Sign up" name="email" v-else>
        <el-form
          class="login-form level"
          ref="loginForm"
          :model="form"
          :rules="rules1"
        >
          <el-form-item prop="user">
            <p class="label">
              <i class="iconfont iconduanxinma"></i>
              <label>Email</label>
            </p>
            <el-input
              v-model="form.user"
              placeholder="Please input Email"
            ></el-input>
          </el-form-item>
          <el-form-item prop="captcha">
            <p class="label">
              <i class="iconfont iconyanzhengma"></i>
              <label>Captcha</label>
            </p>
            <el-input v-model="form.captcha" placeholder="Captcha"></el-input>
            <img
              style="height: 32px;width: 96px;border-radius: 4px;"
              alt="Click Refresh"
              title="Refresh"
              class="pointer"
              :src="codeUrl"
              @click="refreshCode"
            />
          </el-form-item>

          <!-- <el-form-item prop="verCode">
            <p class="label">
              <i class="iconfont iconduanxinma"></i>
              <label>EmailCode</label>
            </p>
            <el-input max="6" v-model="form.verCode" placeholder="Please input EmailCode"></el-input>
            <div class="code" @click="sendCode" v-if="!isSendCode">Send EmailCode</div>
            <div class="code" v-else>{{count}}S Resend</div>
          </el-form-item> -->
          <el-form-item prop="password">
            <p class="label">
              <i class="iconfont iconmima1"></i>
              <label>Password</label>
            </p>
            <el-input
              type="password"
              v-model="form.password"
              placeholder="Please input password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="passwordConfirm">
            <p class="label">
              <i class="iconfont iconmima1"></i>
              <label>Confrim</label>
            </p>
            <el-input
              type="password"
              v-model="form.passwordConfirm"
              placeholder="Please input Password again"
              @keyup.enter.native="submitRegister"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="sign_up_hidden_faiUuid">
            <p class="label">
              <i class="iconfont iconwodeyaoqing"></i>
              <label>InviteCode</label>
            </p>
            <el-input
              v-model="form.sign_up_hidden_faiUuid"
              placeholder="not required"
            ></el-input>
          </el-form-item> -->
          <el-form :inline="true">
            <el-form-item class="btn">
              <a class="btn-black" @click="submitRegister">Sign up</a>
            </el-form-item>
            <el-form-item class="btn">
              <a class="btn-border" @click="goBack">
                <i class="iconfont iconyonghu"></i>
                <span>Sign in</span>
              </a>
            </el-form-item>
          </el-form>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  signUp,
  getVerificationCode,
  checkCaptcha,
  getCaptcha,
} from "../../../api/auth";
import regexJs from "../../../utils/regex";
import { message } from "@/utils/resetMessage";
export default {
  computed: {
    updateSendCodeCountState() {
      return this.$store.state.common.sendCodeCount;
    },
  },
  data() {
    return {
      timeInterval: 0,
      count: this.$store.state.common.sendCodeCount, //120
      isSendCode: this.$store.state.common.sendCodeCount != 120 ? true : false, //false
      registeredType: "phone",
      form: {
        user: "",
        password: "",
        captcha: "",
        passwordConfirm: "",
        sign_up_hidden_faiUuid: this.$route.query.faiUuid,
        verCode: "",
        type: 1,
      },
      codeUrl: "",
      uuid: "",
      rules: {
        user: [
          { required: true, message: "please input phone", trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "Please input Captcha", trigger: "blur" },
        ],
        password: [
          { required: true, message: "please input password", trigger: "blur" },
        ],
        passwordConfirm: [
          {
            required: true,
            message: "please input password again",
            trigger: "blur",
          },
        ],
        verCode: [
          {
            required: true,
            message: "please input phoneCode",
            trigger: "blur",
          },
        ],
      },
      rules1: {
        user: [
          { required: true, message: "Please input Email", trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "Please input Captcha", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Please input Password", trigger: "blur" },
        ],
        passwordConfirm: [
          {
            required: true,
            message: "Please input Password again",
            trigger: "blur",
          },
        ],
        // verCode: [{ required: true, message: "Please input EmailCode", trigger: "blur" }]
      },
    };
  },
  created() {
    this.overseasRegisterMail();
    //刷新验证码
    //this.refreshCode();
    if (this.count != 120) {
      //定时未完成，启动定时器
      this.timeInterval = setInterval(() => {
        if (this.count <= 0) {
          clearInterval(this.timeInterval);
          this.isSendCode = false;
          this.count = 120;
          this.$store.commit("updateSendCodeCountState", this.count);
        } else {
          this.count--;
          this.$store.commit(
            "updateSendCodeCountState",
            this.count
          ); /* 提交vuex状态改变 */
        }
      }, 1000);
    }
  },
  destroyed() {
    clearInterval(this.timeInterval);
  },
  methods: {
    submitRegister() {
      // var faiUuid = this.$route.query.faiUuid;//window.location.href;
      // this.form.sign_up_hidden_faiUuid = faiUuid;
      // console.log("faiuuid = " + this.form.sign_up_hidden_faiUuid);
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (
            !(
              regexJs.telephone.test(this.form.user) ||
              regexJs.email.test(this.form.user)
            )
          ) {
            message.error({
              message: "Incorrect Email!",
              duration: 4000,
              offset: 80,
            });
            return false;
          }
          if (this.form.password != this.form.passwordConfirm) {
            // todo: 密码不一致提示
            message.error({
              message: "Passwords does not match!",
              duration: 4000,
              offset: 80,
            });
            return false;
          }
          this.$showLoading(false, false, "Loading...");
          this.judgeUserType();
          signUp(this.form)
            .then((result) => {
              this.$hideLoading();
              if (result) {
                message.success({
                  message: "Sign up successful!",
                  duration: 4000,
                  offset: 80,
                });
                this.$router.push({ path: "/login" });
              }
            })
            .catch(function(errorResult) {
              this.$hideLoading();
              message.error({
                message: errorResult,
                duration: 4000,
                offset: 80,
              });
            });
        }
      });
    },
    judgeUserType() {
      /* this.form.type = regexJs.email.test(this.form.user)
        ? constants.loginNameType.email
        : constants.loginNameType.phone;*/
      //根据选择注册类型判断
      if (this.registeredType == "email") {
        this.form.type = constants.loginNameType.email;
      } else {
        this.form.type = constants.loginNameType.phone;
      }
    },
    sendCode() {
      if (!this.form.user || !this.form.captcha) {
        return;
      }
      if (this.registeredType == "email") {
        //邮箱不符合
        if (!regexJs.email.test(this.form.user)) {
          message.error({
            message: "Incorrect Email",
            duration: 4000,
            offset: 80,
          });
          return;
        }
      } else {
        if (!regexJs.telephone.test(this.form.user)) {
          //手机号不符合
          message.error({
            message: "Incorrect Mobile",
            duration: 4000,
            offset: 80,
          });
          return;
        }
      }
      this.$showLoading(false, false, "Loading...");
      this.judgeUserType();
      let captchaparam = {
        captcha: this.form.captcha,
        uuid: this.uuid,
      };
      checkCaptcha(captchaparam)
        .then((result) => {
          //验证码正确才发送短信
          let param = {
            user: this.form.user,
            type: this.form.type,
            sendCodeType: constants.sendCodeType.register,
          };
          getVerificationCode(param)
            .then((result) => {
              this.$hideLoading();
              this.isSendCode = true;
              this.timeInterval = setInterval(() => {
                if (this.count <= 0) {
                  clearInterval(this.timeInterval);
                  this.isSendCode = false;
                  this.count = 120;
                  this.$store.commit("updateSendCodeCountState", this.count);
                } else {
                  this.count--;
                  this.$store.commit(
                    "updateSendCodeCountState",
                    this.count
                  ); /* 提交vuex状态改变 */
                }
              }, 1000);
            })
            .catch((errorResult) => {
              this.$hideLoading();
              this.isSendCode = false;
              message.error({
                message: errorResult,
                duration: 4000,
                offset: 80,
              });
            });
        })
        .catch((errorResult) => {
          this.$hideLoading();
          message.error({
            message: errorResult,
            duration: 4000,
            offset: 80,
          });
          //验证码错误，刷新
          this.refreshCode();
        });
    },
    goBack() {
      this.$router.push({ path: "/login" });
    },
    refreshCode: function() {
      //this.src = window.baseUrl+"/api/auth/captcha.jpg?t=" + new Date().getTime();
      getCaptcha({})
        .then((res) => {
          console.log(JSON.stringify(res));
          this.codeUrl = res.img;
          this.uuid = res.uuid;
        })
        .catch((error) => {
          message.error({
            message: "Failed,Pleas try again!",
            duration: 4000,
            offset: 80,
          });
        });
    },
    handleTabClick: function(tab, event) {
      //切换清空输入
      this.form.user = "";
      (this.form.password = ""),
        (this.form.captcha = ""),
        (this.form.passwordConfirm = "");
      //(this.form.verCode = "");
      //清空校验

      //判断注册类型
      if (this.registeredType == "email") {
        this.form.type = constants.loginNameType.email;
      } else {
        this.form.type = constants.loginNameType.phone;
      }
      //刷新验证码
      this.refreshCode();
    },
    overseasRegisterMail() {
      //海外注册，选择邮箱
      //切换清空输入
      this.form.user = "";
      (this.form.password = ""),
        (this.form.captcha = ""),
        (this.form.passwordConfirm = "");
      //(this.form.verCode = "");
      //清空校验

      //修改注册类型
      this.registeredType = "email"; //邮箱
      this.form.type = constants.loginNameType.email;
      //刷新验证码
      this.refreshCode();
      setTimeout(this.clearErrorHint, 20); //显示时会有错误提示，20毫秒后清除错误提示。
    },
    clearErrorHint() {
      if (this.$refs["loginForm"] !== undefined) {
        this.$refs["loginForm"].resetFields();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding-top: 25px;
  .tit {
    margin-bottom: 50px;
    overflow: hidden;
    h3 {
      float: left;
      font-size: 24px;
      color: #000;
    }
    .wei {
      float: right;
      line-height: 30px;
      color: #7e7e7e;
      font-size: 14px;
      cursor: pointer;
      .iconfont {
        margin-right: 10px;
        color: #5acd54;
        font-size: 16px;
      }
    }
  }
  .login-form {
    .el-form-item {
      &.btn {
        margin: 25px 0;
      }
    }
  }
}
.overseasText {
  float: right;
  line-height: 20px;
  color: #7e7e7e;
  font-size: 10px;
  cursor: pointer;
}
</style>
